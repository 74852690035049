<template lang="pug">
  .wrapper.i-wrap
    .section-left
      LeftBar
    .section-mid(v-if="!loading")
      .article-container
        .article-poster
          img(v-bind:src="article.poster")
        .article-wrap
          h1.article-title {{article.title}}
          .article-type {{article.typeOfArticle}}
          .article-publish-author 
            p.publish-date 
              <i class="far fa-clock"></i> 
              |{{article.publishDate | luxon('MMMM月 dd日 yyyy')}} - 
              router-link(:to="`/profile/${article.user._id}`") {{article.user.displayName}}
          .article-content(v-html="article.content" style="font-size:16px;line-height: 1.6em;")
    .section-mid(v-else)
      Spinner
    .section-right

</template>

<script>

import Spinner from 'vue-simple-spinner'
import mtesCard from '@/components/mtesCard'
import LeftBar from '@/components/LeftBar'
import RightBar from '@/components/RightBar'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'Article',
  components: {
    Spinner,
    mtesCard,
    LeftBar,
    RightBar,
  },
  props:{
    id:String,
  },
  data(){
    return{
      loading:true,
      article:null,

    }
  },
  metaInfo() {
    var x = this.loading;
    if(!x){
      var theName = this.article.title;
      var metaStuff = [
        {property: 'og:title', content: this.article.title},
        {property: 'og:type', content: 'article'},
        {property: 'og:url', content: `https://stinkytofu.tw/article/${this.article._id}`},
        {property: 'og:description', content: this.article.summary || ' ' },
        {property: 'og:image', content: `${this.article.poster}`},
        // {property: 'twitter:image:src', content: `${this.mtes.thumbnail}`},
        // {property: 'og:image:width', content: '1000'},
        {property: 'og:site_name', content: '臭豆腐 - 電影∣影集∣評分∣評論'},
        {property: 'article:tag', content:this.article.keywords}
      ]
    }
    return{
      meta:metaStuff,      
      title: this.loading ? 'Loading...': theName,
      titleTemplate: "%s - 臭豆腐 - 電影∣影集∣評分∣評論"
    }
    
  },
  jsonld(){
    var x = this.loading;
    var jsonldInfo = {
      "@context": "http://schema.org/",
      "@type": "NewsArticle",
      
    };
    if (!x){
      jsonldInfo.headline = this.article.title
      jsonldInfo.url = `https://stinkytofu.tw/article/${this.article._id}`
      jsonldInfo.mainEntityOfPage = `https://stinkytofu.tw/article/${this.article._id}`
      jsonldInfo.image = this.article.poster
      jsonldInfo.isAccessibleForFree = "http://schema.org/True"
      jsonldInfo.description = this.article.summary
      jsonldInfo.keywords = [...this.article.keywords]
      jsonldInfo.datePublished = this.article.publishDate
      jsonldInfo.dateModified = this.article.updatedAt
      jsonldInfo.author = {
        "@type":"Person",
        "name":this.article.user.displayName,
        "sameAs":`https://stinkytofu.tw/profile/${this.article.user._id}`
      },
      jsonldInfo.publisher = {
        "@type":"Organization",
        "name": "臭豆腐 - 電影∣影集∣評分∣評論",
        "url": "https://stinkytofu.tw",
        "logo":{
          "@type":"ImageObject",
          "url":"https://storage.googleapis.com/tofu-reviews/img/logo/stinky1.png",
          "width": 246,
          "height": 170
        }
      }
    }
    return jsonldInfo
  },
  methods:{
    
  },
  async created(){
    let article = await this.axios.get(`/api/article/${this.id}`)
    this.article = article.data;
    this.loading = false;
  }
}
</script>
<style lang="scss">
  .article-content{
    img{
      max-width:70%;
    }
  }
</style>
<style lang="scss" scoped>
.article-container{
  margin:15px 0;
  .article-poster{
    // max-height:500px;
    // overflow:hidden;
    img{
      width:100%;
    }
  }
  .article-wrap{
    max-width:80%;
    margin:0 auto;
    .article-title{
      font-size:32px;
      margin:25px 0;
      text-align:center;
    }
    .article-type{
      background-color:#F1564D;
      margin:0 auto;
      padding: 3px 6px;
      font-size: 14px;
      text-align:center;
      color: white;
      width: 40px;
      
    }
    .article-publish-author{
      margin:20px 0;
      text-align:center;
      font-size: 11px;
      color: #8d9299;
      a{
        text-decoration: underline;
      }
    }
    .article-content{
      
    }
  }
  
}
</style>
